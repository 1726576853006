<template>
  <div>
    <div class="text-4xl font-maxime text-white text-center mb-4">
      {{ $t('themes') }}
    </div>
    <gallery ref="gallery" />
    <div class="grid md:grid-cols-4 sm:grid-cols-2 gap-12 m-12">
      <div
        v-for="theme in themes"
        :key="theme.key"
        @click="onClick(theme)"
        class="cursor-pointer"
      >
        <div
          class="bg-cover bg-center h-24 border-2 rounded-lg"
          :style="styleBackground(theme)"
        >
          <div class="text-white text-center font-maxime text-2xl">
            {{ $t(`topics.${theme.key}`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import themesData from '../assets/themes.json';
import Gallery from '../components/Gallery.vue';

export default {
  components: { Gallery },
  computed: {
    themes() {
      return themesData.themes.map((theme) => {
          return theme || null;
      });
    },
  },
  methods: {
    onClick(theme) {
      const images = this.getIllustrations(theme.key);
      console.log(images);
      this.$refs.gallery.$viewerApi({
        options: {
          toolbar: false,
          navbar: false,
          title: false,
        },
        images,
      });
    },
    getIllustrations(key) {
      const illustrations = require.context(
        `../../public/themes/`,
        true,
        /^.*\.*$/
      )
      return illustrations.keys().filter(path => path.includes(`/${key}/`)).map((i) => {
        return i.replace('./', `themes/`);
      });
    },
    styleBackground(theme) {
      return `background-image: url(${encodeURI(this.getIllustrations(theme.key)[0])})`;
      
    }
  }
}
</script>
